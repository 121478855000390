<template>
  <div>
    <ejs-dialog
      ref="RoomStatusManagementPopup"
      header="객실 상태 변경"
      :width="300"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :animationSettings="{ effect: 'None' }"
      :close="onRoomStatusManagementPopupClosed"
    >
      <div class="window realTimeReservation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-title">객실 상태 변경{{` [${roomTitle}호]`}}</div>
                  </div>
                  <div class="section-body">
                    <div
                      v-for="(code, index) in roomStatusCommonCodes"
                      :key="index"
                    >
                      <div
                        class="toggle"
                        :style="{
                          backgroundColor: `${code.colorValue}`,
                        }"
                        @click="onClickRoomCodeItem(code)"
                      >
                        {{ code.comName }}
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="windowFooter">
        <ul class="button">
          <li class="close">
            <erp-button
              button-div="CLOSE"
              v-on:click.native="closeRoomStatusManagementPopup"
            >
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </ejs-dialog>
  </div>
</template>
<style scoped>
.toggle {
  margin: 10px 0;
  padding: 16px 0;
  font-size: 1.8em;
  font-family: "NanumSquare-Bold";
  text-align: center;
  color: black;
  border-radius: 10px;
  border: 3px solid gray;
}
.toggle:hover {
  cursor: pointer;
  filter: brightness(90%);
}
</style>
<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "RoomStatusManagementPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  props: {
    roomTitle: {
      type: String,
    },
  },
  data() {
    return {
      roomStatusCommonCodes: null,
    };
  },
  async mounted() {
    this.roomStatusCommonCodes = commonCodesGetCommonCode("ROOM_STATUS");
  },
  methods: {
    onRoomStatusManagementPopupClosed() {
      this.$emit("popupClosed");
    },
    closeRoomStatusManagementPopup() {
      this.$refs.RoomStatusManagementPopup.hide();
    },
    async onClickRoomCodeItem(item) {
      if (await this.confirm(`${this.roomTitle}호 객실상태를 ${item.comName} 로 변경하시겠습니까?`)) {
        this.$emit("popupClosed", item.comCode);
        this.infoToast(`${this.roomTitle}호 객실상태가 변경되었습니다.`);
      }
    },
  },
};
</script>