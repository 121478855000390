<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">객실사업장</div>
            <ul class="content">
              <li class="item long-string">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="roomBsnCodeDropDown"
                  :dataSource="dropDownDataSourceSet.roomBizDiv"
                  :fields="commonDropDownField"
                  v-model="searchOption.roomBizDiv"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">객실타입</div>
            <ul class="content">
              <li class="item string">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="roomTypeDropDown"
                  :dataSource="dropDownDataSourceSet.roomType"
                  :fields="commonDropDownField"
                  v-model="searchOption.roomType"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">동</div>
            <ul class="content">
              <li class="item number">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="dongDivDropDown"
                  :dataSource="dropDownDataSourceSet.dongDiv"
                  :fields="commonDropDownField"
                  v-model="searchOption.dongDiv"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">층</div>
            <ul class="content">
              <li class="item multi-select">
                <ejs-multiselect
                  ref="floorMultiSelect"
                  cssClass="lookup-condition-multiselect"
                  placeHolder="전체"
                  :dataSource="dropDownDataSourceSet.floor"
                  :fields="commonDropDownField"
                  v-model="searchOption.floor"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onSearchBtnClicked"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field">
            <div class="title">투숙객명</div>
            <ul class="content">
              <li class="item string">
                <input-text
                  ref="inputVisitorRef"
                  v-model="searchVisitorName"
                  @blur="onBlurDetailSearchInput"
                  @keydown.enter.native="onClickDetailSearch"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">객실번호</div>
            <ul class="content">
              <li class="item number">
                <input-text
                  v-model="searchRoomNo"
                  :maxlength="14"
                  @keydown.enter.native="onClickDetailSearch"
                  @blur="onBlurDetailSearchInput"
                />
              </li>
            </ul>
          </li>
          <div class="lookup-lookup">
            <erp-button
                button-div="GET"
                :ignore="isPopupOpened"
                @click.native="onClickDetailSearch"
            >
              찾기
            </erp-button>
          </div>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        ref="useAutoRefreshCheckBox"
                        type="checkbox"
                        v-model="PageConfig.useAutoRefresh"
                        @change="onChangeAutoRefresh"
                      />
                      <i></i>
                      <div class="label">자동조회</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item number">
                <input-number
                  id="autoRefreshInput"
                  v-model="PageConfig.autoRefreshSecond"
                  :max="99999999"
                  @change="onChangeAutoRefresh"
                ></input-number>
              </li>
            </ul>
            <div class="title">초 (다음갱신 : {{PageConfig.remainingSecond}}초)</div>
          </li>
        </ul>
      </div>
      <ul class="lookup-button">
        <li class="shortcut">
          <ejs-dropdownbutton
            ref="shortcutMenuDropdownButton"
            cssClass="lookup-button-dropdown"
            :items="shortcutMenuItems"
            @select="shortcutMenuSelected"
          >
            바로가기
          </ejs-dropdownbutton>
        </li>
      </ul>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">Room Indicator</div>
              <div class="header-caption">[{{ haveRoomCount }}]</div>
              <ul class="header-label">
                <li class="field">
                  <ul class="content">
                    <li
                      class="item"
                      v-for="(condition, idx) in eachRoomConditionSummaryInfo"
                      :key="idx"
                    >
                      <i
                        v-bind:style="{
                          'background-color': condition.colorValue
                        }"
                      ></i>
                      <div class="label">
                        {{ condition.comName }}
                      </div>
                      <div class="label">{{ condition.count || 0 }}</div>
                    </li>

                    <div class="title">청소상태 :</div>
                    <li class="item">
                      <i
                        v-bind:style="{
                          'background-color': commonCodesGetColorValue(
                            'CLEAN_STATUS',
                            'DIRTY'
                          )
                        }"
                      ></i>
                      <div class="label">
                        Dirty
                      </div>
                      <div class="label">{{ currentRoomListDirtyCount }}</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="table-body"
            :class="[
              roomDrag.start ? 'section-body draggable' : 'section-body'
            ]"
          >
            <div class="room-wrapper">
              <div
                class="room-total"
                style="padding: 6px; box-sizing: border-box"
                :style="{
                  width: `calc(100% / ${roomMaxColIndex + 1})`,
                }"
                v-for="(room, idx) in roomList"
                :key="idx"
              >
                <div v-if="room">
                  <div
                    :class="[roomDrag.start ? 'room draggable' : 'room']"
                    :style="{
                      backgroundColor: room.colorValue
                        ? room.colorValue
                        : '#ffffff'
                    }"
                    :room-tag-id="room.roomId"
                    @mouseover="onRoomItemHover(room)"
                    @mouseleave="onRoomItemMouseLeave(room)"
                  >
                    <div
                      class="triangle-base"
                      :style="{
                        cursor: 'pointer',
                        borderTopColor:
                          commonCodesGetColorValue(
                            'CLEAN_STATUS',
                            `${room.cleanStatus}`
                          ) || '#FFFFFF',
                        borderLeftColor:
                          commonCodesGetColorValue(
                            'CLEAN_STATUS',
                            `${room.cleanStatus}`
                          ) || '#FFFFFF'
                      }"
                      @click="cleanStatusTriangleClicked($event, room)"
                    ></div>
                    <div
                      class="etc-button"
                      :etc-tag-id="room.roomId"
                      @click="roomEtcButtonClicked($event, room)"
                    ></div>
                    <div
                      class="header"
                      :style="{
                        backgroundColor:
                          commonCodesGetColorValue(
                            'ROOM_STATUS',
                            `${room.realRoomStatus}`
                          ) || '#FFFFFF'
                      }"
                    >
                      <span v-if="room.dvrUseFlag">
                        <div>
                          <span class="room-no">{{ room.roomNo }}</span>
                          <span>{{
                            convertRoomCodeToString("ROOM_TYPE", room.roomType)
                          }}</span>
                          <span
                            v-if="
                              convertRoomCodeToString(
                                'ROOM_KIND',
                                room.roomKind
                              ) === '테마'
                            "
                          >
                            테마</span
                          >
                        </div>
                        <span>{{
                          room.guestName ? room.guestName : room.dvrUserName
                        }}</span>
                      </span>
                      <span v-else>
                        <div>
                          <span class="room-no">{{ room.roomNo }}</span>
                          <span>{{
                            convertRoomCodeToString("ROOM_TYPE", room.roomType)
                          }}</span>
                          <span
                            v-if="
                              convertRoomCodeToString(
                                'ROOM_KIND',
                                room.roomKind
                              ) === '테마'
                            "
                          >
                            테마</span
                          >
                        </div>
                        <span>{{ room.guestName || "" }}</span>
                      </span>
                    </div>
                    <div class="room-using-period-label">
                      {{ getStayDate(room) }}
                    </div>
                  </div>
                  <div
                    class="room-footer-btn"
                    @click="roomFooterButtonClicked(room)"
                  >
                    {{
                      convertRoomCodeToString("ROOM_STATUS", room.realRoomStatus)
                    }}
                  </div>
                </div>
                <div
                  v-else
                  :class="[roomDrag.start ? 'room draggable' : 'room']"
                  :style="{
                    opacity: 0.5,
                    height: '108px'
                  }"
                >
                  <div class="header">X</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
    <clean-status-management-popup
      v-if="isCleanStatusPopupOpen"
      :room-title="currentRoom.roomNo"
      @popupClosed="cleanStatusManagementPopupClosed"
    >
    </clean-status-management-popup>
    <room-status-management-popup
      v-if="isRoomStatusPopupOpen"
      :room-title="currentRoom.roomNo"
      @popupClosed="roomStatusManagementPopupClosed"
    >
    </room-status-management-popup>
    <available-room-popup
      v-if="isAvailableRoomPopupOpen"
      ref="availableRoomPopup"
      @popupClosed="onAvailableRoomPopupClosed"
      @popupConfirm="onAvailableRoomPopupConfirm"
    />
    <erp-button
      button-div="GET"
      :is-custom-shortcut-button="true"
      :shortcut-key="keyBoardPropsAvailableRoomPopupShow.shortcutKey"
      :shortcut="keyBoardPropsAvailableRoomPopupShow.shortcut"
      @click.native="showAvailableRoomPopupOpen"
      v-show="false"
    >
      바로가기
    </erp-button>
  </div>
</template>

<style scoped>
body
  .appContent
  .content-wrapper
  .content-lookup
  .lookup-condition
  .field
  .content
  .item.long-string {
  width: 160px;
}

body
  .appContent
  .content-wrapper
  .content-lookup
  .lookup-condition
  .field
  .content
  .item.string {
  width: 120px;
}

body
  .appContent
  .content-wrapper
  .content-lookup
  .lookup-condition
  .field
  .content
  .item.number {
  width: 70px;
}

body
  .appContent
  .content-wrapper
  .content-lookup
  .lookup-condition
  .field
  .content
  .item.multi-select {
  width: 180px;
}

.draggable {
  cursor: move !important;
}

.room-wrapper {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
}

.room-total:hover{
  box-shadow: 0 0 0 1px #000 inset;
}

.room {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 84px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.room .triangle-base {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  border-bottom: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top-width: 12px;
  border-top-style: solid;
  border-left-width: 12px;
  border-left-style: solid;
}
.room .triangle-base:hover {
  filter: brightness(90%);
}
.room .etc-button {
  display: none;
  top: 2px;
  right: -2px;
  position: absolute;
  width: 23px;
  height: 23px;
  padding: 0;
  background: transparent
    url("../../../assets/images/common/button-icon-etc.png") no-repeat center
    center;
  filter: brightness(0%);
}
.etc-button:hover {
  cursor: pointer;
  filter: brightness(120%);
}
.room > .header {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  font-family: "NanumSquare-Bold";
  text-align: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 0 8px;
}

.room > .header .room-no {
  font-size: 1.8em;
  margin-right: 8px;
}

.room .room-using-period-label {
  height: 20%;
  padding: 6px;
  margin: auto;
  font-family: "NanumSquare-Bold";
  text-align: center;
}

.room-wrapper .room-footer-btn {
  width: 100%;
  margin-top: 4px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  font-family: "NanumSquare-Bold";
}
.room-wrapper .room-footer-btn:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.focused-room {
  border: 3px solid red;
}
</style>

<script>
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputNumber from "@/components/common/InputNumber";
import { openNewWindow } from "@/utils/appInfo";
import InputText from "@/components/common/text/InputText";
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
  commonCodesGetComName
} from "@/utils/commonCodes";
import { maxBy as _maxBy, sortBy as _sortBy } from "lodash";
import _uniqBy from "lodash/uniqBy";
import _countBy from "lodash/countBy";
import CleanStatusManagementPopup from "@/views/room-front/room-management/popup/CleanStatusManagementPopup";
import RoomStatusManagementPopup from "@/views/room-front/room-management/popup/RoomStatusManagementPopup";
import AvailableRoomPopup from "@/views/room-reservation-management/popup/AvailableRoomPopup";
import { getDateTime } from "@/api/common";
import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import moment from "moment/moment";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "RoomIndicator",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputNumber,
    InputText,
    CleanStatusManagementPopup,
    RoomStatusManagementPopup,
    AvailableRoomPopup,
    ErpButton,
  },
  data() {
    return {
      roomLayoutInfos: {},
      isAvailableRoomPopupOpen: false,
      isRoomStatusPopupOpen: false,
      isCleanStatusPopupOpen: false,
      eachRoomConditionSummaryInfo: null,
      commonDropDownField: { text: "name", value: "value" },
      currentRoom: null,
      PageConfig: {
        useAutoRefresh: false,
        autoRefreshSecond: 0,
        remainingSecond: 0
      },
      refreshTimer: null,
      roomList: [],
      vacantRooms: [],
      searchVisitorName: "",
      searchVisitorNameBuffer: "",
      savedSearchResults: [],
      searchRoomNo: null,
      searchRoomNoBuffer: null,
      roomMaxColIndex: null,
      roomMaxRowIndex: null,
      isPopupOpened: true,
      searchOption: {
        roomBizDiv: "",
        roomType: "",
        dongDiv: "",
        floor: []
      },
      roomDrag: {
        start: false
      },
      dropDownDataSourceSet: {
        roomBizDiv: {},
        roomType: {},
        dongDiv: {},
        floor: []
      }
    };
  },
  async mounted() {
    await this.fetch();

    this.saveFirstFetchDataInfo();
    this.dropDownDataSourceSet.roomBizDiv = this.getDataSourceFromCommonCode(
      "ROOM_BIZ_DIV",
      "roomBizDiv"
    );
    this.dropDownDataSourceSet.roomType = this.getDataSourceFromCommonCode(
      "ROOM_TYPE",
      "roomType"
    );
    this.dropDownDataSourceSet.dongDiv = this.getDataSourceFromCommonCode(
      "DONG_DIV",
      "dongDiv"
    );
    this.dropDownDataSourceSet.floor = this.getDataSourceByWordWrap(
      "floor",
      "층",
      false
    );
    this.dropDownDataSourceSet.floor.shift();
    this.searchOption.dongDiv = this.dropDownDataSourceSet.dongDiv[this.dropDownDataSourceSet.dongDiv.length - 1].value;
    this.$refs.dongDivDropDown.ej2Instances.value = this.searchOption.dongDiv;
    this.calcRoomLayoutSize();
    this.roomList = this.roomLayoutCalc();
    this.eachRoomConditionSummaryInfo = this.getEachRoomSummarySum(
        "ROOM_STATUS"
    );
  },
  methods: {
    commonCodesGetColorValue,
    commonCodesGetComName,
    commonCodesGetCommonCode,
    async setRooms() {
      await this.fetch();
      this.calcRoomLayoutSize();
      this.roomList = this.roomLayoutCalc();
      this.eachRoomConditionSummaryInfo = this.getEachRoomSummarySum(
        "ROOM_STATUS"
      );
    },
    saveFirstFetchDataInfo() {
      this.roomLayoutInfos["maxFloor"] = _maxBy(this.roomList, "floor")?.floor;
      this.roomLayoutInfos["maxRowNo"] = _maxBy(this.roomList, "rowNo")?.rowNo;
      this.roomLayoutInfos["maxColumnNo"] = _maxBy(this.roomList, "columnNo")?.columnNo;
      for (let floor = 1; floor <= this.roomLayoutInfos.maxFloor; floor++) {
        const roomFloor = this.roomList.filter(room => room.floor === floor);
        this.roomLayoutInfos[`floor${floor}`] = {
          columnNo: _maxBy(roomFloor, "columnNo")?.columnNo,
          rowNo: _maxBy(roomFloor, "rowNo")?.rowNo
        };
      }
    },
    getDataSourceFromCommonCode(grpCode, field) {
      let dataSource = this.roomList.filter(Boolean).map(room => {
        let obj = null;
        obj = {
          name: commonCodesGetComName(grpCode, room[`${field}`]),
          value: room[`${field}`].toString()
        };
        return obj;
      });
      dataSource = _sortBy(_uniqBy(dataSource, "value") || [], "value");

      dataSource.unshift({
        name: "전체",
        value: ""
      });
      return dataSource;
    },
    getDataSourceByWordWrap(field, mappingWord, isWordWrapFront) {
      let dataSource = this.roomList.filter(Boolean).map(room => {
        let obj = null;
        obj = {
          name: isWordWrapFront
            ? mappingWord + room[`${field}`]
            : room[`${field}`] + mappingWord,
          value: room[`${field}`].toString()
        };
        return obj;
      });
      dataSource = _sortBy(_uniqBy(dataSource, "value") || [], "value");

      dataSource.unshift({
        name: "전체",
        value: ""
      });
      return dataSource;
    },
    async fetch() {
      const { value } = await getDateTime();
      const nowDate = value.split(" ")[0];

      this.roomList = await GolfErpAPI.fetchVacantRoom({
        arrivalDate: nowDate,
        departureDate: nowDate,
        roomType: this.searchOption.roomType,
        dongDiv: this.searchOption.dongDiv,
        floors: this.searchOption.floor
      });
      this.roomList = this.roomList.filter(
        room => room.useFlag && !room.virtlRoomFlag
      );
    },
    calcRoomLayoutSize() {
      if (this.searchOption.dongDiv) {
        this.roomMaxColIndex = this.roomLayoutInfos.maxColumnNo;
        this.roomMaxRowIndex = this.roomLayoutInfos.maxRowNo;
      } else {
        //fix size by records (동을 전체로 조회할때는 기본 가로 아이템 갯수 10개 고정)
        this.roomMaxColIndex = 9;
        this.roomMaxRowIndex =
          Math.floor(this.roomList.length / (this.roomLayoutInfos.maxColumnNo + 1)) + 1;
      }
    },
    roomLayoutCalc() {
      let rooms = [];
      const maximumRecordRowNo = this.roomLayoutInfos.maxRowNo;
      const START_IDX = 0;
      if (this.searchOption.dongDiv) {
        const maxFloor = this.roomLayoutInfos.maxFloor;
        const allFloor = this.dropDownDataSourceSet.floor.map(obj => obj.value);
        Array.prototype.diff = function(arr) {
          return this.filter(x => !arr.includes(x));
        };
        const difference = allFloor.diff(this.searchOption.floor);
        for (let floor = maxFloor; floor > START_IDX; floor--) {
          if (
            this.searchOption.floor.length &&
            difference.includes(floor.toString())
          ) {
            continue;
          }
          const eachFloor = this.groupByFloor(this.roomList, floor);
          for (let rowNo = START_IDX; rowNo <= maximumRecordRowNo; rowNo++) {
            const eachRow = this.groupByRowIndex(eachFloor, rowNo);
            rooms = rooms.concat(this.fillRoom(eachRow));
          }
        }
        return rooms;
      }
      return _sortBy(this.roomList || [], "roomNo");
    },
    groupByFloor(list, floor) {
      let groupObj = list.filter(Boolean).filter(room => room.floor === floor);
      return groupObj;
    },
    groupByRowIndex(list, rowNo) {
      let groupObj = list.filter(Boolean).filter(room => room.rowNo === rowNo);
      if (this.searchOption.dongDiv) {
        groupObj = _sortBy(groupObj || [], "columnNo");
      } else {
        groupObj = _sortBy(groupObj || [], ["columnNo", "sortNo"]);
      }
      return groupObj;
    },
    fillRoom(roomList) {
      const idxArr = [];
      const START_IDX = 0;
      for (let i = START_IDX; i <= this.roomLayoutInfos.maxColumnNo; i++) {
        if (!roomList.find(room => room.columnNo === i)) {
          idxArr.push(i);
        }
      }
      idxArr.forEach(num => {
        roomList.splice(num, 0, null);
      });
      return roomList;
    },
    async onSearchBtnClicked() {
      await this.setRooms();

      this.searchRoomNo = null;
      this.searchVisitorName = "";
    },
    roomFooterButtonClicked(room) {
      if (!room.useFlag) {
        this.errorToast("미사용 객실입니다. 사용여부를 확인하여 주십시오.");
        return;
      }
      this.currentRoom = room;
      switch (room.realRoomStatus) {
        case "NAMING":
          return this.moveToNewPage('RoomReservationRegistration', {stayId: room.stayId, bsnDate: room.arrivalDate});
        case "VACANT":
          //TODO : 객실예약등록 컴포넌트의 check-in process로 이동
          if (room.checkinAvail !== "Y") {
            this.errorToast(
                `${room.roomNo}호 체크인을 진행할 수 없습니다. 프론트에 문의하여 주십시오.`
            );
            return;
          }
          return this.moveToNewPage('RoomReservationRegistration', {roomType : room.roomType, roomNo : room.roomNo, roomId: room.roomId});
        case "OCCUPIED":
          //TODO : 객실예약등록 컴포넌트의 check-out process로 이동
          return this.moveToNewPage('RoomReservationRegistration',{stayId : room.stayId, roomId : room.roomId, isCheckIn: false});
        case "ABSENCE":
          //부재중
          //TODO
          return;
        case "OUTOF":
          //고장
          //TODO
          return;
        case "HOUSE":
          //직원사용
          //TODO
          return;
        default:
          return;
      }
    },
    moveToNewPage (name, queryParam = null) {
      let routeData = this.$router.resolve({
        name: name,
        ...(!queryParam || {query: {...queryParam}}),
      });
      openNewWindow(routeData);
    },
    roomEtcButtonClicked(event, room) {
      event.stopPropagation();
      this.currentRoom = room;
      this.isRoomStatusPopupOpen = true;
    },
    cleanStatusTriangleClicked(event, room) {
      event.stopPropagation();
      this.currentRoom = room;
      this.isCleanStatusPopupOpen = true;
    },
    onRoomItemHover(room) {
      this.showEtcButton(room, true);
    },
    onRoomItemMouseLeave(room) {
      this.showEtcButton(room, false);
    },
    showEtcButton(room, visibility) {
      const etcButtons = document.querySelectorAll(".etc-button");
      const findBtn = Array.prototype.find.call(
        etcButtons,
        btn => btn.getAttribute("etc-tag-id") === room.roomId
      );
      findBtn.style.display = visibility ? "block" : "none";
    },
    onClickDetailSearch() {
      this.clearFocusedObjects();

      if (!this.searchRoomNo && !this.searchVisitorName) {
        return;
      }
      if (
        this.searchVisitorNameBuffer !== this.searchVisitorName ||
        this.searchRoomNoBuffer !== this.searchRoomNo
      ) {
        this.savedSearchResults = [];
      }

      let findVisitor = [];
      const roomLists = this.roomList?.filter(Boolean);
      if (this.searchVisitorName) {
        findVisitor = roomLists.filter(room => {
          if (room.guestName) {
            if (room.guestName?.includes(this.searchVisitorName)) {
              return room;
            }
            return false;
          } else {
            if (
              room.dvrUseFlag &&
              room.dvrUserName?.includes(this.searchVisitorName)
            ) {
              return room;
            }
            return false;
          }
        });
      } else {
        findVisitor = roomLists;
      }

      if (this.searchRoomNo) {
        findVisitor = findVisitor.filter(
          room => room.roomNo === this.searchRoomNo
        );
      }
      if (!findVisitor.length) {
        this.errorToast("일치하는 결과를 찾을 수 없습니다.");
        return;
      }
      if (!this.savedSearchResults.length) {
        const findTags = findVisitor.map(room => {
          return document.querySelector(`[room-tag-id="${room.roomId}"]`);
        });
        this.savedSearchResults = findTags;
      }
      if (this.savedSearchResults.length > 0) {
        this.savedSearchResults[0].classList.add("focused-room");
        this.savedSearchResults[0].scrollIntoView();
        this.savedSearchResults.shift();
      }
      this.searchVisitorNameBuffer = this.searchVisitorName;
      this.searchRoomNoBuffer = this.searchRoomNo;
    },
    onBlurDetailSearchInput() {
      this.savedSearchResults = [];
      this.clearFocusedObjects();
    },
    clearFocusedObjects() {
      const clearObjs = document.querySelectorAll(".focused-room");
      [].forEach.call(clearObjs, el => {
        el.classList.remove("focused-room");
      });
    },
    onChangeAutoRefresh() {
      if (this.PageConfig.useAutoRefresh && this.PageConfig.autoRefreshSecond) {
        this.PageConfig.remainingSecond = this.PageConfig.autoRefreshSecond;
        this.refreshTimer = setInterval(async () => {
          this.PageConfig.remainingSecond--;
          if (this.PageConfig.remainingSecond <= 0) {
            await this.setRooms();
            this.PageConfig.remainingSecond = this.PageConfig.autoRefreshSecond;
          }
        }, 1000);
      } else {
        if (this.refreshTimer) {
          clearInterval(this.refreshTimer);
          this.refreshTimer = null;
          this.PageConfig.remainingSecond = 0;
        }
      }
    },
    getEachRoomSummarySum(commonGrpCode) {
      const commonRoomStatusCodes = commonCodesGetCommonCode(commonGrpCode);
      const currentStatus = _countBy(
        this.roomList.filter(Boolean).map(room => room.realRoomStatus)
      );

      commonRoomStatusCodes.forEach(status => {
        const key = Object.keys(currentStatus).find(
          key => status.comCode === key
        );
        if (key) {
          status["count"] = currentStatus[`${key}`];
        }
      });
      return commonRoomStatusCodes;
    },
    convertRoomCodeToString(groupCode, codeValue) {
      const commonRoomStatusCodes = commonCodesGetCommonCode(groupCode);
      return commonRoomStatusCodes.find(code => code.comCode === codeValue)
        .comName;
    },
    async patchRoom(room, fieldName, value) {
      // NOTE:
      // patchRoomDetail api를 통하여 새로운 room 상태 업데이트시, realRoomStatus가 아닌 roomStatus로 업데이트를 쳐야 값이 갱신된다.
      // 화면상에서는 roomStatus가 아닌 realRoomStatus Property로 색상이나 라벨을 찍기 때문에 currentRoom Data에 roomStatus, realRoomStatus 둘 다 업데이트 쳐줘야함.(221111)
      if(fieldName === "roomStatus"){
        this.currentRoom.realRoomStatus = value;
      }
      this.currentRoom[`${fieldName}`] = value;
      await GolfErpAPI.patchRoomDetailNumber(this.currentRoom);
    },
    onAvailableRoomPopupClosed() {
      this.isAvailableRoomPopupOpen = false;
    },
    onAvailableRoomPopupConfirm(data) {
      this.moveToNewPage('RoomReservationRegistration', {bsnDate: data.bsnDate, roomType: data.roomType});
    },
    async roomStatusManagementPopupClosed(roomStatusCode) {
      this.isRoomStatusPopupOpen = false;
      if (!roomStatusCode) {
        return;
      }
      await this.patchRoom(this.currentRoom, "roomStatus", roomStatusCode);
    },
    async cleanStatusManagementPopupClosed(cleanStatusCode) {
      this.isCleanStatusPopupOpen = false;
      if (!cleanStatusCode) {
        return;
      }
      await this.patchRoom(this.currentRoom, "cleanStatus", cleanStatusCode);
    },
    getStayDate(room) {
      const idx = room.arrivalDate?.toString().indexOf("-");
      const lastIdx = room.arrivalDate?.toString().length;
      return room.arrivalDate && room.departureDate && room.stayCnt
        ? `${room.arrivalDate.substring(
            idx + 1,
            lastIdx
          )}~${room.departureDate.substring(idx + 1, lastIdx)} (${
            room.stayCnt
          }박)`
        : "";
    },
    showAvailableRoomPopupOpen() {
      this.isAvailableRoomPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.availableRoomPopup.showPopup({
          bsnDate: moment().format("YYYY-MM-DD"),
        });
      });
    },
    shortcutMenuSelected(args) {
      if (args.item.id === 1) {
        this.showAvailableRoomPopupOpen();
        return;
      }
    },
  },
  computed: {
    keyBoardPropsAvailableRoomPopupShow() {
      return {
        shortcutKey: "RoomReservationRegistration.shortcuts.availableRoom",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12
        }
      };
    },
    shortcutMenuItems() {
      return [
        {
          id: 1,
          text: "가용객실[F12]"
        }
      ];
    },
    haveRoomCount() {
      return this.roomList.filter(Boolean).length;
    },
    currentRoomListDirtyCount() {
      return this.roomList
        .filter(Boolean)
        .filter(r => r.cleanStatus !== "CLEAN").length;
    }
  }
};
</script>
